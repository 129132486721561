.example {
  margin: 30px 15%;
  .title {
    margin: 30px 0;
    font-size: 28px;
    padding: 10px 0 20px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ddd;
    position: relative;
    &::after {
      content: "";
      width: 100px;
      height: 3px;
      background-color: #ff6d00;
      position: absolute;
      left: calc(50% - 50px);
      bottom: -1px;
    }
  }

  .images {
    padding: 0 10px;
    display: flex;
    justify-content: space-evenly;
    .img-container {
      display: flex;
      flex-direction: column;
    }
    .img {
      width: 200px;
      height: 200px;
    }
    .img-desc {
      color: #666666;
      font-size: 14px;
      margin: 10px 0;
    }
  }
}

.header-banner {
  height: 30px;
  line-height: 30px;
  padding: 0 15%;
  font-size: 14px;
  background-color: #f7f7f7;
  text-align: left;
  box-shadow: 2px 0px 9px 1px #ddd;
  color: #1e50ae;
}
.header {
  display: flex;
  flex-direction: revert;
  padding: 0 15%;
  height: 130px;
  align-items: center;
  color: #1e50ae;
  .title {
    flex-basis: 70%;
    text-align: center;
    font-size: 28px;
    text-align: left;
    .desc-img{
      vertical-align: middle;
      border-style: none;
      width: 40px;
      margin-right: 8px;
    }
    .text {
      color: rgb(30, 80, 174);
      margin: 10px 0;
      font-weight: bold;
      display: inline-block;
    }
    .desc {
      font-size: 18px;
      margin-left: 47px;
      // color: #666666;
    }
  }
  .phone {
    text-align: right;
    display: flex;
    .phone-icon {
      margin-right: 10px;
    }
    .phone-desc {
      font-size: 20px;
      // color: #666666;
      text-align: left;
      .title{
        display: inline-block;
        margin: 14px 0;
        font-size: 18px;
      }
      .phone-icon-img{
        display: inline-block;
        vertical-align: sub;
        width: 42%;
        margin-left: 10px;
      }
    }
    .phone-number {
      font-size: 18px;
      // color: rgb(33, 33, 33);
    }
  }
  // .phone {
  //   text-align: right;
  //   display: flex;
  //   .phone-icon {
  //     margin-right: 10px;
  //   }
  //   .phone-desc {
  //     font-size: 14px;
  //     // color: #666666;
  //     text-align: left;
  //   }
  //   .phone-number {
  //     font-size: 22px;
  //     // color: rgb(33, 33, 33);
  //   }
  // }
}

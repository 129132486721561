.footer {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  color: #fff;
  background-color: #282828;
  text-align: center;
}

.beian{
  color: grey;
}

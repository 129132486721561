.banner {
  width: 100%;
  height: 300px;
  display: flex;
  background-color: #132f61;
  align-items: center;
  line-height: 1.4;
  margin: 20px 0;
  .info {
    flex-basis: 70%;
    color: #fff;
    font-size: 30px;

    line-height: 1.5;
    .title {
      color: #ff6d00;
    }
    .desc {
      font-size: 20px;
    }
  }
  .image-container {
    height: 300px;
    .img {
      height: 300px;
    }
  }
}

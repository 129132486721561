.carousel-item {
  margin: 0;
  height: 500px;
  line-height: 500px;
  color: #fff;
  text-align: center;
  background: #364d79;
  .carousel-img {
    width: 100%;
    height: 500px;
  }
}
